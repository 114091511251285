import React, { useState } from 'react'

// Mantine Elements
import {
    AppShell,
    Navbar,
    Header,
    Footer,
    Aside,
    Text,
    MediaQuery,
    Burger,
    useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';


// Papes
import NavBar from './pages/header/NavBar';
import Wrapper from './Wrapper';
import SideNav from './pages/sidenav/SideNav';

//Css
import "./App.css"
import Icons from './pages/icons/Icons';

const App = () => {

    // useState
    const [opened, setOpened] = useState(false);

    // Media Query
    const Query = useMediaQuery('(max-width:768px)')
    return (
        <div>
            <AppShell
                padding={0}
                m={0}
                navbarOffsetBreakpoint="sm"
                asideOffsetBreakpoint="sm"
                navbar={
                    <Navbar hiddenBreakpoint="sm" hidden={!opened} width={{ sm: 0, lg: 0 }}
                        style={{ opacity: "0.9", backgroundColor: " rgba(0, 0, 0, 0.445)" }}>
                        {Query ? <SideNav setOpened={setOpened} /> : ""}
                    </Navbar>
                }
                header={
                    <Header height={{ base: 70, md: 90 }} p="md" style={{ boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.326)" }}>
                        <div
                            style={{ display: 'flex', alignItems: 'center', height: '100%' }}
                        >
                            <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                                <Burger
                                    opened={opened}
                                    onClick={() => setOpened((o) => !o)}
                                    size="sm"
                                    color={"black"}
                                    mr="xl"
                                />
                            </MediaQuery>
                            <NavBar />
                        </div>
                    </Header>
                }
            >
                <Wrapper />
                <Icons />
                <br />
            </AppShell>
        </div>
    )
}

export default App