import React from 'react'
import { Switch, Route } from "react-router-dom"

// Pages 
import Footer from "./pages/footer/Footer"
import Loader from './Loader'
const Home = React.lazy(() => import('./pages/home/Home'))
const About = React.lazy(() => import('./pages/about/About'))
const Service = React.lazy(() => import('./pages/service/Service'))
const Gallery = React.lazy(() => import('./pages/gallery/Gallery'))
const Contact = React.lazy(() => import('./pages/contact/Contact'))

const Wrapper = () => {
    return (
        <div>
            <Switch>
                <React.Suspense fallback={<Loader />}>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="/about">
                        <About />
                    </Route>
                    <Route path="/service">
                        <Service />
                    </Route>
                    <Route path="/gallery">
                        <Gallery />
                    </Route>
                    <Route path="/contact">
                        <Contact />
                    </Route>
                </React.Suspense>
            </Switch>
            <footer>
                <Footer />
            </footer>
        </div>
    )
}

export default Wrapper