import React from 'react'
import "./NavBar.css"
import logo from "../../Assests/images/Sized_Images/Logo.jpg"
import { Text } from '@mantine/core'
import { Link } from "react-router-dom"

const NavBar = () => {
    return (
        <div className='NavBar'>
            <div className="Navlogo">
                <img src={logo} data-aos="fade-right" data-aos-duration="1000" data-aos-once="true" alt="Brand_Logo" />
            </div>
            <div className="navList">
                <ul>
                    <li data-aos="fade-left" data-aos-duration="1000" data-aos-once="true" data-aos-delay="100">
                        <Text component={Link} to="/">
                            Home
                        </Text>
                    </li >
                    <li data-aos="fade-left" data-aos-duration="1000" data-aos-delay="300" data-aos-once="true">
                        <Text component={Link} to="/about">
                            About
                        </Text>
                    </li>
                    <li data-aos="fade-left" data-aos-duration="1000" data-aos-delay="500" data-aos-once="true">
                        <Text component={Link} to="/service">
                            Service
                        </Text>
                    </li>
                    <li data-aos="fade-left" data-aos-duration="1000" data-aos-delay="700" data-aos-once="true">
                        <Text component={Link} to="/gallery">
                            Gallery
                        </Text>
                    </li>
                    <li data-aos="fade-left" data-aos-duration="1000" data-aos-delay="900" data-aos-once="true">
                        <Text component={Link} to="/contact">
                            Contact Us
                        </Text>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default NavBar