import React from 'react'
import "./Icons.css"

// Images
import Call from "../../Assests/images/Call_Svg.png"
import WhatsApp from "../../Assests/images/WhatsApp_Svg.png"
const Icons = () => {
    return (
        <div className='Icon_page'>
            <div className="call" data-aos="fade-right" data-aos-duration="1000" data-aos-once="true">
                <a href="tel:+91 80986 78889">
                    <img src={Call} alt="" />
                </a>
            </div>
            <div className="whatsApp" data-aos="fade-left" data-aos-duration="1000" data-aos-once="true">
                <a href="https://wa.me/+918098678889">
                    <img src={WhatsApp} alt="" />
                </a>
            </div>
        </div>
    )
}

export default Icons