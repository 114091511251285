import React from 'react'
import { Loader as Load } from '@mantine/core'
import "./App.css"

const Loader = () => {
    return (
        <div className='loader-page'>
            <div className='Loading'>
                <Load size="xl" variant="bars" />
                <h5> Loading </h5>
            </div>
        </div>
    )
}

export default Loader
