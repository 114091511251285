import React from "react";
import "./Footer.css";

// Mantine Elements
import { Center, Title, Grid, Card, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";


// Router
import { Link } from "react-router-dom"


// Logo Image
import Logo from "../../Assests/images/Sized_Images/Logo.jpg"
import SquareBox from "../../Assests/images/Square_Box_Logo.png"

import Aos from "aos";


const Footer = () => {

    // AOS Init
    Aos.init()

    // Media Query
    const Query = useMediaQuery('(max-width:1060px)')

    return (
        <div>
            <div className="footer_container">
                <div className="footer_container_top" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">
                    <Title order={Query ? 6 : 3}>
                        Location
                    </Title>
                    <Center>
                        {/* <iframe
                            className="iframe_map"
                            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d15666.997179977607!2d78.076603!3d10.9822864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2s!5e0!3m2!1sen!2sin!4v1693979367361!5m2!1sen!2sin"
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                        ></iframe> */}
                        <iframe
                            className="iframe_map"
                            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3891.575098897137!2d77.81758407507253!3d12.741118987553465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDQ0JzI4LjAiTiA3N8KwNDknMTIuNiJF!5e0!3m2!1sen!2sin!4v1695280408232!5m2!1sen!2sin"
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                    </Center>
                </div>
                <div className="footer_container_bottom">
                    <Grid className="Footer_Grid">
                        <Grid.Col
                            data-aos="zoom-in"
                            data-aos-duration="1000"
                            data-aos-once="true"
                            span={Query ? "" : 1}
                            style={{
                                marginTop: `${Query ? "" : "2%"}`,
                                display: "flex",
                                justifyContent: `${Query ? "" : "end"}`
                            }}>
                            <img src={Logo} alt="" />
                        </Grid.Col>
                        <Grid.Col data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true" span={Query ? "" : 5}>
                            <Card>
                                <Card.Section>
                                    <Title order={Query ? 6 : 3}>
                                        About Us
                                    </Title>
                                </Card.Section>
                                <Text>
                                    We are VMK Pest management, one of the top and most reputable
                                    service providers in Hosur and the surrounding areas. with
                                    more than six years of expertise, we have a comprehensive
                                    understanding of pest management. We have skilled pest control
                                    professionals on staff. To learn more about our service,
                                    contact us today.
                                </Text>
                            </Card>
                        </Grid.Col>
                        <Grid.Col data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true" span={Query ? "" : 3}>
                            <Card>
                                <Card.Section>
                                    <Title order={Query ? 6 : 3}>
                                        Address
                                    </Title>
                                </Card.Section>
                                <Text>
                                    <ul className="ul_li_none">
                                        <li>Shop No.58/A-13(First Floor),</li>
                                        <li>M/S Tanu Shree Radha Complex,</li>
                                        <li>Banglore Road,</li>
                                        <li>Hosur,</li>
                                        <li>Krishanagiri District.</li>
                                    </ul>
                                </Text>
                            </Card>
                        </Grid.Col>
                        <Grid.Col data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true" span={Query ? "" : 2}>
                            <Card>
                                <Card.Section>
                                    <Title order={Query ? 6 : 3}>
                                        Quick Links
                                    </Title>
                                </Card.Section>
                                <ul className="ul_li_none2">
                                    <li><Text component={Link} to="/">Home</Text></li>
                                    <li><Text component={Link} to="/about">About</Text></li>
                                    <li><Text component={Link} to="/service">Service</Text></li>
                                    <li><Text component={Link} to="/gallery">Gallery</Text></li>
                                    <li><Text component={Link} to="/contact">Contact us</Text></li>
                                </ul>
                            </Card>
                        </Grid.Col>
                    </Grid>
                </div>
            </div>
            <hr />
            <div >
                <Center >
                    <Text className="foot">
                        @2023 VMK. All Rights Reserved.&nbsp;Designed by &nbsp;
                        <br />
                        <div className="align_cntr">
                            <img src={SquareBox} className="square_box" />
                            <a href="#"> Square Box</a>
                        </div>
                    </Text>
                </Center>
            </div>
            <br />
        </div>
    );
};

export default Footer;
