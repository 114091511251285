import React from 'react'
import "./SideNav.css"
import { Text } from '@mantine/core'
import { Link } from "react-router-dom"

const SideNav = ({ setOpened }) => {
    return (
        <div className='SideNav'>
            <ul>
                <li onClick={() => setOpened(false)}>
                    <Text component={Link} to="/">
                        Home
                    </Text>
                </li>
                <li onClick={() => setOpened(false)}>
                    <Text component={Link} to="/about">
                        About
                    </Text>
                </li>
                <li onClick={() => setOpened(false)}>
                    <Text component={Link} to="/service">
                        Service
                    </Text>
                </li>
                <li onClick={() => setOpened(false)}>
                    <Text component={Link} to="/gallery">
                        Gallery
                    </Text>
                </li>
                <li onClick={() => setOpened(false)}>
                    <Text component={Link} to="/contact">
                        Contact Us
                    </Text>
                </li>
            </ul>
        </div>
    )
}

export default SideNav