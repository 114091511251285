import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from "react-router-dom"
import App from './App'
import { ModalsProvider } from '@mantine/modals'
import { MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'

ReactDOM.render(
    <HashRouter>
        <MantineProvider>
            <ModalsProvider>
                <Notifications position="top-right" autoClose={2200} withBorder />
                <App />
            </ModalsProvider>
        </MantineProvider>
    </HashRouter>,
    document.getElementById('root')
)